import React from 'react';
import "slick-carousel/slick/slick.css";
import {graphql } from 'gatsby'
// import "~slick-carousel/slick/slick-theme.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../scss/bootstrap-overrides.scss"
import "../scss/global.scss";
import "../scss/grunticon.scss";
import Header from '../Components/Header/Header';
import Footer from '../Components/Footer/Footer';
import BreadCrumbs from '../Components/PropertyDetails/BackButton/BreadCrumbs'
import Property from "../Components/CarrierLanding/PropertyPrice/PropertyInfo";
import LatestNews from "../Components/CarrierLanding/News/News";
import GalleryDetails from "../Components/CarrierLanding/GalleryDetails/GalleryDetails";
import SEO from '../Components/Seo/seo';

//import { NewsDetail } from '../queries/common_use_query';
const NewsDetails = (props) => {
    //const {loading, error, data} = NewsDetail(props.url);
    const GQLPage = props.data.glstrapi?.newsBlog;
    const metadesc = "Read about "+GQLPage.Title+" here and subscribe to our newsletter stay up-to-date about everything going on at Balgores.";
    return (
        <React.Fragment>
            <div className="wrapper property-wrap news-details-page">
                <div className="inner-header"><Header /></div>    
                        <SEO title={GQLPage.Meta_Title?GQLPage.Meta_Title:GQLPage.Title} description={metadesc?metadesc:GQLPage.Meta_Description} />                                     
                        <BreadCrumbs alias="latest-property-news" baselabel="news" baseurl={"/about/latest-property-news/"} itemlabel={GQLPage.Title}/>
                        <Property newsdata={GQLPage}/>
                        <LatestNews />
                        <GalleryDetails newsurl={GQLPage.URL} />                   
                <Footer popularSearch="Popular_Search_Static_Common" />
            </div>
        </React.Fragment>
    )
}
export default NewsDetails;

export const newsdetailQuery = graphql`
query NewsQuery($id: ID!) {
  glstrapi {
    newsBlog(id: $id) {  
      id
      Title
      URL
      Meta_Title
      Meta_Description
      News_Published_Date
      Banner_Image {
        url
        alternativeText
      }
      Short_Intro
      CTA_Below_Short_Intro {
        CTA_Label
        CTA_Link {
          Primary_URL
        }
        CTA_Custom_Link
      }
      Full_Content
      imagetransforms  
    }
}
}`

